@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

@import "bootstrap/scss/bootstrap";
/* src/index.css */
@import '~sweetalert2/src/sweetalert2.scss';



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  
  
  body{
    overflow-x: hidden;
    background-color: black;
  }