
.logo{
    display: flex;
    flex-direction: column;
    color: black;
     

    @media (max-width: 768px){
        padding: 3rem 0;
        margin-top: 2rem;  
    }
    .logo-top{
        display: flex;
        line-height: 49px;
        @media (max-width: 330px){
            justify-content: center;
        }
        >span{
            font-size: 3rem;
            font-weight: bolder;
            @media (max-width: 330px){
                font-size: 1rem;
            }
            
            &:last-child{
                font-weight: 200;
            }
        }
    }

    .logo-bottom{
        >span{
            font-size: 1.4rem;
            font-weight: 200;
            text-transform: uppercase;
            letter-spacing: 1.2px;

            @media (max-width: 330px){
                font-size: .8rem;
            }
        }
    }
}