.toolsIUse{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 0;

    h2{
        color: white;
        font-size: 2rem;

        @media (max-width: 768px){
            text-align: center;
        }
    }

    .images-icons{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        
        @media (max-width: 768px){
            justify-content: center;
            gap: 3rem;
        }
        
        img{
            transition: all 300ms ease;
            width: 100px;
            object-fit: cover;
            cursor: pointer;

            &:hover{
                scale: 1.2;
            }
        }
    }
}