
.project-card{
    display: flex;
    align-items: center;
    column-gap: 2rem;
    background-color: rgba(211, 211, 211, 0.128);
    border-radius: 20px;
    padding: 2rem 1rem;

    transform: translateX(-150%);
    animation: leftToRight 2s ease-in-out 1 forwards;

    @media (max-width: 992px){
        flex-direction: column!important;
        text-align: center;
        gap: 2rem;

    }

   .row-reverse{
       flex-direction: row-reverse;
       transform: translateX(150%);
   }

  

    .images-box{
        flex: 1;
        display: flex;
        column-gap: 1rem;
        overflow: hidden;


        img{
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
           
        }
    }

    .project-info{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        color: white;

        @media (max-width: 768px){
            align-items: stretch;
            
        }
        
        h3{
            font-size: 3rem;
            color: goldenrod;

        }
        p{
            text-align: justify;
        }

        >a{
            text-decoration: none;
            color: white;
            border: 1px solid goldenrod;
            background-color: goldenrod;
            outline: none;
            padding: .5rem 2rem;
            border-radius: 15px;
            transition: all 300ms ease;
            &:hover{
                color: goldenrod;
                background-color: black;
                border: 1px solid goldenrod;
            }
        }
    }
}

@keyframes leftToRight {
    0% {
        transform: translateX(-150%);
    
    }
    100% {
        transform: translateX(0%);
        
    }
}

@keyframes rightToLeft {
    0% {
        transform: translateX(150%);
       
    }
    100% {
        transform: translateX(0%);
       
    }
}

