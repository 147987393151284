.contact-form{
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h1{
        font-size: 1.5rem;
        font-weight: 200;
    }

    button{
        background-color: black;
        text-transform: uppercase;
        font-weight: 200;
        letter-spacing: 2px;
        border: 1px solid goldenrod;

        &:hover{
            background-color: goldenrod;
            color: black;
            border: 1px solid black;
        }
    }

   
}