
.navbar{
    display: flex;
    gap: 1rem;

    

    a{
        color: black;
        text-decoration: none;
        transition: all 300ms ease;

        &:hover{
            color: goldenrod;
        }
    }
}

.nav-main{
@media (max-width: 768px){
        display: none!important;
    }
}

.offcanvas-header{

    .btn-close{
        position: absolute;
        top: 2.4rem;
        right: 1.5rem;
    }
}

.offcanvas-menu{
    display: none;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    @media (max-width: 768px){
        display: block;
    }

    .canvas-btn{
        color: black;
        font-size: 2rem;
        &:hover{
            cursor: pointer;
        }
    }

}

.offcanvas{
    background-color: transparent!important;
    background: linear-gradient(135deg, rgba(255,255,255,0.1), rgbs(255,255,255,0))!important;
    backdrop-filter: blur(20px)!important;
    -webkit-backdrop-filter: blur(7px)!important;
    z-index: 99999999!important;
    color: white!important;
}

.navbar-canvas{
    display: flex;
    flex-direction: column;
    
    >a{
        width: 100%;
        padding: .5rem .5rem;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;

        &:hover{
            background-color: goldenrod;
            color: black;
        }
    }
}