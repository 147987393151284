


.portfolio-card{
    display: flex;
    // background-color: #F5F5F5;
    background-color: rgba(0, 0, 0, 0.837);
    border-radius: 30px;
    cursor: pointer;
    box-shadow:0px 1px 10px rgba(218, 165, 32, 0.458);

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;

    &::after{
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0));
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
 
        
    }

    

    .folder{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 9999;



        &:hover{
            .folder-top{
                transform: translate(-200px,-55px) rotate(-60deg);

                img{
                    animation: none;
                }
            }

            span{
                opacity: 0;
            }


            .button{
                z-index: 9999;
                opacity: 1!important;

            }

            .clone-images{
                transform: translateY(-180px);

                @media (max-width: 768px){
                    transform: translateY(-220px);
                }

                img{
                    transform: scale(1.2);

                    &:first-child{
                        animation: slideLeft 2s ease-in-out 1 forwards;
                    }

                    &:last-child{
                        animation: slideRight 2s ease-in-out 1 forwards;
                    }
                }
            }
        }

        >span{
            color: white;
            background-color: goldenrod;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 200;
            transition: all 500ms ease;
        }



        .folder-top{
            // z-index: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            transition: all 500ms ease-in-out;
            width: 300px;
            height: 300px;
            mix-blend-mode: darken;

            
            img{
                width: 100%;
                object-fit: cover;
                animation: openClose 2000ms ease-in-out 1 forwards ;
            }
        }

        .folder-bottom{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 300px;
            height: 300px;
            overflow: hidden;
            position: relative;
            img{
                width: 100%;
                object-fit: cover;
            }

            &::after{
                content: "";
                position: absolute;
                inset: 5px 5px 136px 5px;
                background-color: rgba(81, 78, 78, 0.325);
                border-radius: 10px;
                z-index: 99;
            }
            &::before{
                content: "";
                position: absolute;
                inset: 8px 8px 139px 8px;
                background-color: rgba(0, 0, 0, 0.042);
                border-radius: 10px;
                border: 1px dotted grey;
                z-index: 999;
            }

            .button{
                position: absolute;
                text-decoration: none;
                padding: .5rem 2rem;
                background-color: goldenrod;
                border: 1px solid white;
                color: white;
                border-radius: 15px;
                font-size: .9rem;
                letter-spacing: 1.4px;
                top: 60px;
                transition: all 500ms ease;
                box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
                opacity: 0;


                &:hover{
                    background-color: rgba(218, 165, 32, 0.462);
                }
                
            }
        }
    
      
    
        .clone-images{
            position: absolute;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            transition: all 500ms ease-in-out;  
            bottom: 290px;
            // z-index: -1;

            @media (max-width: 768px){
                bottom: 305px;
            }
            
    
            img{
                width: 100%;
                object-fit: cover;
                position: absolute;
                transition: all 300ms ease;


                &:first-child{
                    top: 18px;
                    width: 50%;
                }

                &:last-child{
                    top: 18px;
                    width: 14%;
                }
            }
        }
    }
}

@keyframes slideLeft {
    0%{
        transform: translateX(0%);
    }
    80%{
        transform: translateX(-7%);
    }
    100%{
        transform: translateX(-15%);
        scale: 1.5;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    }
}
@keyframes slideRight {
    0%{
        transform: translateX(0%);
    }
    80%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(150%);
        scale: 1.5;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    }
}

@keyframes openClose {
    0%{
        
        transform: translate(-5px,-5px) rotate(-4deg);
    }
    50%{
        transform: translate(-10px,-10px) rotate(-8deg);
    }
    
    100%{
        
        transform: translate(0,0px) rotate(0deg);
    }
}






  