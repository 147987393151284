.header{
    background-color: white;
    padding: 1rem;
    border-bottom: 1px solid rgba(211, 211, 211, 0.482);

    .header-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 768px){
            flex-direction: column;
            gap: 2rem;
        }
    }
} 