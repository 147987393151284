

.my-info{
    display: flex;
    justify-content: center;
    margin-top: 50%;

    @media (max-width: 992px){
        margin-top: 10%;
        margin-bottom: 4rem;
    }
    .social{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
    }
}