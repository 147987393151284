.footer{
    padding: 2rem 0;
    background-color: white;
}

.footer-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    @media (max-width: 768px){
        flex-direction: column;
    }

    .footer-top{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;

    }
}

.copy{
    color: rgba(218, 165, 32, 0.771);
}