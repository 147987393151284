
.info{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 2rem 0;

    @media (max-width: 992px){
        flex-direction: column;
        gap: 2rem;
    }
}

.social-container{
    &:hover{
        cursor: pointer;
        scale: 1.1;
    }
}


.info-top{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    color: white;
    transform: translateX(-150%);
    animation: slideLeftToCenter 2s ease-in-out 1 forwards;

    @media (max-width: 992px){
        align-items: center;
        text-align: center;
        padding: 1rem 0;
    }


    h1{
        font-size: 3rem;
        line-height: 10px;
    }
    h2{
        font-size: 5rem;
        span{
            color: goldenrod;
            font-weight: 200;
        }
    }
    h3{
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }
    >.button{
        border: 1px solid goldenrod;
        color: white;
        outline: none;
        padding: 1rem 2rem;
        border-radius: 15px;
        transition: all 300ms ease;
        text-decoration: none;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        margin-top: 1rem;
        &:hover{
            color: goldenrod;
            border: 1px solid goldenrod;
        }
    }

    
}

.info-image{
    position: relative;
    display: flex;
    animation: slideRightToCenter 2s ease-in-out 1 forwards;
    img{
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
        z-index: 1;
    }


    .white{
        width: 650px;
        height: 650px;
        background-color: white;
        position: absolute;
        left: -7px;
        top: -7px;
        transform: translate(-50%,-50%);
        z-index: 0;
        border-radius: 50%;
        animation: spinWhite 7s ease-in-out infinite;
        @media (max-width: 1440px){
            display: none;
        }

        >.box{
            width: 30px;
            height: 30px;
            margin-left: 250px;
            transform: rotate(-60deg);
            background-color: goldenrod;
        }

        @keyframes spinWhite {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
    }
    
}


@keyframes slideLeftToCenter {
    0% {
        transform: translateX(-150%);
    
    }
    100% {
        transform: translateX(0%);
        
    }
}
@keyframes slideRightToCenter {
    0% {
        transform: translateX(150%);
       
    }
    100% {
        transform: translateX(0%);
       
    }
}