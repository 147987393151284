

.sw-form{
   display: flex;
   flex-direction: column;
   align-items: center;
   min-width: 150px;
   
   @media (max-width: 998px){
    min-width: none;
    min-width: 70px;
}

    @media(max-width: 768px){
    position: absolute;
    align-items: flex-start;
    top: 2rem;
    left: 1rem;
}

   .sw-label{

    @media (max-width: 998px){
        display: none;
    }
   }

   
}