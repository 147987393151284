.landing {
    display: flex;
    align-items: center;
    justify-content: center;

   

    span{
        font-size: 13rem;
        color: white;
        font-weight: 200;

        @media (max-width: 992px){
            font-size: 6rem;
        }
    }

    .left {
        flex: 1;
        background-color: black;
        height: 100vh;
        animation: slideLandLeft 3s ease-in-out 1 forwards;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        svg{
            font-size: 15rem;
            color: white;
            transform: translateX(58px);

            @media (max-width: 992px){
                font-size: 5rem;
                transform: translateX(0px);
            }
        }
    }

    .right {
        flex: 1;
        background-color: black;
        height: 100vh;
        animation: slideLandRight 3s ease-in-out 1 forwards;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        svg{
            font-size: 15rem;
            color: white;
            transform: translateX(-58px);
            @media (max-width: 992px){
                font-size: 5rem;
                transform: translateX(-20px);
            }
        }
    }

    @keyframes slideLandLeft {
        0% {
            transform: translateX(0%);
            opacity: 1;
        }
        90% {
            transform: translateX(-95%);
            opacity: 0.4;
        }
        100% {
            transform: translateX(-100%);
            opacity: 0.1;
        }
    }

    @keyframes slideLandRight {
        0% {
            transform: translateX(0%);
            opacity: 1;
        }
        90% {
            transform: translateX(95%);
            opacity: 0.4;
        }
        100% {
            transform: translateX(100%);
            opacity: 0.1;
        }
    }
}
